import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { get, map, isEmpty } from 'lodash/fp';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { media } from '../../styles/mixins';
import { fonts } from '../../styles/typography';
import { EXTERNAL_PAGE } from '../../constants/cmsTypes';

export const MagicButtons = ({
  buttons = [],
  centerButtons = false,
  buttonColor = null,
  hideOnMobile = false,
  wide = false,
}) => {
  if (isEmpty(buttons)) return false;

  return (
    <MagicBlockButtonContainer
      centerButtons={centerButtons}
      hideOnMobile={hideOnMobile}
    >
      {map((button) => {
        if (
          !get('heading', button) ||
          typeof get(['link', 'id'], button) !== 'string' ||
          !get(['link', 'type'], button)
        )
          return false;

        const isExternalPage = button?.link?.type === EXTERNAL_PAGE;

        if (isExternalPage) {
          return (
            <MagicBlockButton
              key={get(['link', 'id'], button)}
              href={get(['link', 'id'], button)}
              as="a"
              target="_blank"
              isBlack={buttonColor === 'whiteOnBlack'}
              isWhite={buttonColor === 'blackOnWhite'}
              whiteBorder={buttonColor === 'whiteBorder'}
              blackBorder={buttonColor === 'blackBorder'}
              wide={wide}
            >
              <p>{get('heading', button)}</p>
            </MagicBlockButton>
          );
        }

        return (
          <LocalizedLink
            key={get(['link', 'id'], button)}
            as={get(['link', 'id'], button)}
            data={{ id: get(['link', 'id'], button) }}
            page={get(['link', 'type'], button)}
          >
            <MagicBlockButton
              isBlack={buttonColor === 'whiteOnBlack'}
              isWhite={buttonColor === 'blackOnWhite'}
              whiteBorder={buttonColor === 'whiteBorder'}
              blackBorder={buttonColor === 'blackBorder'}
              wide={wide}
            >
              <p>{get('heading', button)}</p>
            </MagicBlockButton>
          </LocalizedLink>
        );
      }, buttons)}
    </MagicBlockButtonContainer>
  );
};

const MagicBlockButtonContainer = styled.div`
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: ${(props) =>
    props.centerButtons ? 'center' : 'flex-start'};

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      ${media.M`
        display: flex;
        margin: 35px -8px 0;
        width: auto;
      `}
    `}
`;

const MagicBlockButton = styled.button`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  padding: 0 15px;
  text-decoration: none;
  width: 100%;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.black};
  border: 1px solid ${(props) => props.theme.color.black};
  background-color: transparent;
  cursor: pointer;
  margin: 5px 0;
  transition: background-color 0.25s ease 0s, color 0.25s ease 0s,
    border-color 0.25s ease 0s;

  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  ${({ theme, isBlack }) =>
    isBlack &&
    css`
      color: ${theme.color.white};
      background: ${theme.color.black};
      border-color: ${theme.color.black};
    `};

  ${({ theme, isWhite }) =>
    isWhite &&
    css`
      color: ${theme.color.black};
      background: ${theme.color.white};

      &:hover {
        color: ${theme.color.white};
        background: ${theme.color.black};
        border-color: ${theme.color.black};
      }
    `};

  ${({ theme, whiteBorder }) =>
    whiteBorder &&
    css`
      color: ${theme.color.white};
      background: transparent;
      border-color: ${theme.color.white};

      &:hover {
        color: ${theme.color.black};
        background: ${theme.color.white};
        border-color: ${theme.color.white};
      }
    `};

  ${({ theme, blackBorder }) =>
    blackBorder &&
    css`
      color: ${theme.color.black};
      border: 1px solid ${theme.color.black};
    `}

  p {
    ${fonts.NeoSansRegular};
    font-size: 14px;
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: opacity 0.1s ease-in-out 0s;
  }

  ${media.M`
    padding: ${(props) => (props.wide ? '0 60px' : '0 15px')};
    margin: 8px;
    width: auto;
    min-width: 145px;
  `};

  ${media.L`
    min-width: 160px;
  `};
`;

MagicButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  centerButtons: PropTypes.bool,
  buttonColor: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  wide: PropTypes.bool,
};
